import React, { memo, useState } from "react";
import type { KcProps } from "keycloakify";
import type { KcContext } from "./kcContext";
import type { I18n } from "./i18n";
import Template, { StateDialog } from "components/Template";
import { KcTextInput } from "components/KcFormInput";
import { Button, Portal, Text } from "react-native-paper";
import { BaseDomainError, checkIdentity, requestOTP } from "api";
import { Toast } from "react-native-toast-message/lib/src/Toast";
import { View } from "react-native";
import { Image } from "react-native";
import styled from "styled-components";
import { isMobileApp } from "./KcApp";
type KcContext_AuthSelectionLogin = Extract<
  KcContext,
  { pageId: "hues-login-auth-selection.ftl" }
>;

const AuthSelectionLogin = memo(
  ({
    kcContext,
    i18n,
    ...props
  }: { kcContext: KcContext_AuthSelectionLogin; i18n: I18n } & KcProps) => {
    const [loading, setLoading] = useState(false);
    return (
      <View>
        <Template
          {...{ kcContext, i18n, ...props }}
          doFetchDefaultThemeResources={true}
          maxWidth={515}
          headerNode={"Đăng nhập"}
          formNode={
            <>
              <Text
                style={{
                  alignSelf: "center",
                  marginBottom: 24,
                  textAlign: "center",
                }}
              >
                Vui lòng chọn loại tài khoản bạn muốn sử dụng để đăng nhập
              </Text>
              <View>
                {(() => {
                  return kcContext.items.map((e) => {
                    return (
                      <SelectionButton
                        onClick={() => {
                          if (
                            //@ts-ignore
                            window.ReactNativeWebView != null &&
                            e.appDeepLink != null
                          ) {
                            //@ts-ignore
                            window.ReactNativeWebView.postMessage(
                              "deepLink:" +
                                JSON.stringify({
                                  link: e.appDeepLink,
                                  name: e.name,
                                })
                            );
                          } else {
                            setLoading(true);
                            setTimeout(() => {
                              //@ts-ignore
                              document.getElementById("provider").value = e.id;
                              //@ts-ignore
                              document.getElementById("form").submit();
                            }, 15);
                          }
                        }}
                        key={"loginButton_" + e.id}
                        style={{}}
                      >
                        <div style={{ flex: 1 }}>
                          <div style={{ fontWeight: "500" }}> {e.title}</div>
                          <div style={{ fontWeight: "bold", marginTop: -2 }}>
                            {e.name}
                          </div>
                        </div>
                        <img
                          src={e.icon}
                          alt={e.name}
                          style={{
                            width: 52,
                            height: 52,
                            border: "0.1px solid #fff",
                            borderRadius: "12%",
                          }}
                        />
                      </SelectionButton>
                    );
                  });
                })()}
                <form
                  id="form"
                  method="POST"
                  action={kcContext.url.loginAction}
                  style={{ display: "none" }}
                >
                  <input id="provider" name="kc_idp_hint" />
                </form>
              </View>
            </>
          }
          infoNode={
            <Text
              style={{ marginTop: -8, alignSelf: "center", color: "#fff" }}
              variant="titleSmall"
            >
              Tổng đài hỗ trợ:
              <Button
                onPress={() => {
                  window.location.href = "tel:19001075";
                }}
                contentStyle={{ marginLeft: -4 }}
              >
                1900 1075
              </Button>
            </Text>
          }
        />
        <StateDialog state="loading" visible={loading} />
      </View>
    );
  }
);

const SelectionButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: #2477f2;
  text-align: center;
  font-size: 14px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: #2477f2;
  :not(:last-child) {
    margin-bottom: 6px;
  }
  &:hover {
    border-color: #82bdfa;
    color: #fff;
    font-weight: 600;
    background-color: #1e88e5;
  }
`;

export default AuthSelectionLogin;
